import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/www/wwwroot/mangakakalot/src/components/forms/delete-bookmark-form.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListPagination"] */ "/www/wwwroot/mangakakalot/src/components/list-pagination.jsx");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/src/components/ui/carousel.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/www/wwwroot/mangakakalot/src/components/ui/tabs.jsx");
